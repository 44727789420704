import * as React from 'react';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { Link, TLinkP } from './Link';
import { useStaticQuery, graphql } from 'gatsby';
import { GQLTwitterDiscussionLinkQuery } from '../lib/types/graphql/__generated__/gatsby.gql';

export type ITwitterDiscussionLinkP = Omit<TLinkP, 'href' | 'variant' | 'ExternalLinkProps'> & {
    label?: string;
};

export function TwitterDiscussionLink(props: TReactFCP<ITwitterDiscussionLinkP>): TReactFCR {
    const { to, label, ...linkProps }: ITwitterDiscussionLinkP = props;
    const { site }: GQLTwitterDiscussionLinkQuery = useStaticQuery(graphql`
        query TwitterDiscussionLink {
            site {
                siteMetadata {
                    siteCanonicalUrl
                }
            }
        }
    `);
    const fqdnSlug: string = `${site!.siteMetadata!.siteCanonicalUrl!}${to}`;
    return (
        <Link
            variant='external'
            to={`https://mobile.twitter.com/search?q=${encodeURIComponent(fqdnSlug)}`}
            ExternalLinkProps={{ variant: 'body1' }}
            {...linkProps}
        >
            {label || 'Discuss on Twitter'}
        </Link>
    );
}
