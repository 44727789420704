import * as React from 'react';
import clsx from 'clsx';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import {
    RedditShareButton, TwitterShareButton, FacebookShareButton, LinkedinShareButton
} from 'react-share';
import { TwitterSquareIcon, FacebookSquareIcon, RedditIcon, LinkedInSquareIcon } from './FAIcons';
import { UseStyles } from '../lib/types/mui';
import { Theme, Grid, Button, makeStyles } from '@material-ui/core';
import { TwitterDiscussionLink } from './TwitterDiscussionLink';
import ShareIcon from '@material-ui/icons/Share';
import { IconSizes } from '../lib/theme/typography';
import { Tooltip } from './Tooltip';
import { useStaticQuery, graphql } from 'gatsby';
import { GQLBlogPostSocialQuery } from '../lib/types/graphql/__generated__/gatsby.gql';

type StylesProps = { iconSize: IconSizes; };

type ClassKey =
    'container' | 'seperator' | 'shareButtonsContainer' | 'shareCTA' | 'shareCTAIcon' | 'shareCTAText' |
    'socialButton' | 'twitter' | 'facebook' | 'reddit' | 'linkedIn';
const useStyles = makeStyles<Theme, StylesProps, ClassKey>((theme: Theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '100%',
        // alignItems: 'baseline',
        marginBottom: theme.baseline.blockMarginBottom,
        '& > *': { marginBottom: 0 }
    },
    seperator: {
        paddingRight: `${theme.spacing(3)}px`,
        borderRight: `2px solid ${theme.palette.custom.divider.main}`
    },
    shareButtonsContainer: {
        color: theme.palette.custom.text.color.light
    },
    shareCTA: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(1)
    },
    shareCTAIcon: {
        color: theme.palette.custom.text.color.light,
        marginRight: theme.spacing(2)
    },
    shareCTAText: { marginBottom: 0 },
    socialButton: (props: StylesProps) => ({
        ...theme.typography.iconSizing(props.iconSize),
        cursor: 'pointer',
        '&:hover, &:active': { transition: theme.transitions.create('color') }
    }),
    twitter: {
        '&:hover, &:active': { color: theme.palette.custom.brands.twitter }
    },
    facebook: {
        '&:hover, &:active': { color: theme.palette.custom.brands.facebook }
    },
    reddit: {
        '&:hover, &:active': { color: theme.palette.custom.brands.reddit }
    },
    linkedIn: {
        '&:hover, &:active': { color: theme.palette.custom.brands.linkedIn }
    },
}));

export type IBlogPostSocialP = {
    quote?: string;         // Used for a literal quote from the article and the base message text
    description?: string;   // Used for TL;DR purposes in LinkedIn
    hashtags?: string[];
    title?: string;         // Title of the article
    twitterHandle?: string; // Twitter handle of the author
    slug: string;
    iconSize?: IconSizes;
};

export function BlogPostSocial(props: TReactFCP<IBlogPostSocialP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles({ iconSize: props.iconSize || 4 });
    const hashtags: string[] = props.hashtags || [];
    const { quote, title, twitterHandle, slug }: IBlogPostSocialP = props;
    const { site }: GQLBlogPostSocialQuery = useStaticQuery(graphql`
        query BlogPostSocial {
            site {
                siteMetadata {
                    siteCanonicalUrl
                }
            }
        }
    `);
    const url: string = `${site!.siteMetadata!.siteCanonicalUrl!}${slug}`;
    const sharing: React.ReactNode = (
        <Grid className={styles.shareButtonsContainer} container spacing={1} alignItems='baseline' alignContent='center'>
            <Grid item>
                <TwitterShareButton
                    className={clsx(styles.socialButton, styles.twitter)}
                    url={url}
                    title={quote || undefined}
                    via={twitterHandle}
                    hashtags={hashtags}
                >
                    <TwitterSquareIcon fixedWidth />
                </TwitterShareButton>
            </Grid>
            <Grid item>
                <FacebookShareButton
                    className={clsx(styles.socialButton, styles.facebook)}
                    url={url}
                    quote={quote || undefined}
                    hashtag={hashtags.length > 0 ? `#${hashtags[0]}` : undefined}
                >
                    <FacebookSquareIcon fixedWidth />
                </FacebookShareButton>
            </Grid>
            <Grid item>
                <RedditShareButton
                    className={clsx(styles.socialButton, styles.reddit)}
                    url={url}
                    title={title}
                >
                    <RedditIcon fixedWidth />
                </RedditShareButton>
            </Grid>
            <Grid item>
                <LinkedinShareButton
                    className={clsx(styles.socialButton, styles.linkedIn)}
                    url={url}
                >
                    <LinkedInSquareIcon fixedWidth />
                </LinkedinShareButton>
            </Grid>
        </Grid>
    );
    return (
        <div className={styles.container}>
            <div className={styles.seperator}>
                <TwitterDiscussionLink to={props.slug} />
            </div>
            <Tooltip title={sharing} interactive placement='top' enterTouchDelay={0}>
                <div className={styles.shareCTA}>
                    <Button color='inherit'>
                        <ShareIcon className={styles.shareCTAIcon} />
                        Share
                    </Button>
                </div>
            </Tooltip>
        </div>
    );
}
