import * as React from 'react';
import clsx from 'clsx';
import deepmerge from 'deepmerge';
import { TooltipProps } from '@material-ui/core/Tooltip';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { Tooltip as MuiTooltip, Theme, makeStyles } from '@material-ui/core';
import { UseStyles } from '../lib/types/mui';
import { PopperProps } from '@material-ui/core/Popper';

type ClassKey = 'arrow' | 'popper' | 'tooltip';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => {
    const borderColor: string = theme.palette.custom.tooltip.border.color;
    // TOOD: Fix the arrow positioning and color. I still don't fully understand how this works. The MUI site offered
    // it as a suggestion.
    return {
        arrow: {
            position: 'absolute',
            fontSize: 6,
            width: '3em',
            height: '3em',
            '&::before': {
                content: '""',
                margin: 'auto',
                display: 'block',
                width: 0,
                height: 0,
                borderStyle: 'solid',
            }
        },
        popper: {
            '&[x-placement*="bottom"] $arrow': {
                top: 0,
                left: 0,
                marginTop: '-0.95em',
                width: '3em',
                height: '1em',
                '&::before': {
                    borderWidth: '0 1em 1em 1em',
                    borderColor: `transparent transparent ${borderColor} transparent`,
                },
            },
            '&[x-placement*="top"] $arrow': {
                bottom: 0,
                left: 0,
                marginBottom: '-0.95em',
                width: '3em',
                height: '1em',
                '&::before': {
                    borderWidth: '1em 1em 0 1em',
                    borderColor: `${borderColor} transparent transparent transparent`,
                },
            },
            '&[x-placement*="right"] $arrow': {
                left: 0,
                marginLeft: '-0.95em',
                height: '3em',
                width: '1em',
                '&::before': {
                    borderWidth: '1em 1em 1em 0',
                    borderColor: `transparent ${borderColor} transparent transparent`,
                },
            },
            '&[x-placement*="left"] $arrow': {
                right: 0,
                marginRight: '-0.95em',
                height: '3em',
                width: '1em',
                '&::before': {
                    borderWidth: '1em 0 1em 1em',
                    borderColor: `transparent transparent transparent ${borderColor}`,
                },
            },
        },
        tooltip: {
            backgroundColor: theme.palette.custom.tooltip.background.color,
            color: theme.palette.custom.tooltip.background.contrastText.main
        },
    };
});

export type ITooltipP = TooltipProps & {
    arrow?: boolean;
};

export function Tooltip(props: TReactFCP<ITooltipP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const arrowRef: React.Ref<HTMLSpanElement> = React.useRef<HTMLSpanElement>(null);
    const { children, arrow, classes: muiClasses, PopperProps: muiPopperProps, title, ...muiProps }: ITooltipP = props;
    const classes: TooltipProps['classes'] = {
        ...props.classes,
        popper: clsx(styles.popper, muiClasses && muiClasses.popper),
        tooltip: clsx(styles.tooltip, muiClasses && muiClasses.tooltip)
    };
    const popperProps: PopperProps = deepmerge(
        {
            popperOptions: {
                modifiers: {
                    arrow: { enabled: arrow && arrowRef.current !== null, element: arrowRef.current || undefined }
                }
            }
        },
        muiPopperProps || {}
    );
    const tooltip: React.ReactNode = (
        <React.Fragment>
            {title}
            {arrow && <span className={styles.arrow} ref={arrowRef} />}
        </React.Fragment>
    );
    return (
        <MuiTooltip {...muiProps} title={tooltip} classes={classes} PopperProps={popperProps}>{children}</MuiTooltip>
    );
}
