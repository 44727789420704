import * as React from 'react';
// import RehypeReact from 'rehype-react';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { Grid, makeStyles } from '@material-ui/core';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { GQLBlogPostQuery, GQLSitePageContext } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { UseStyles } from '../../lib/types/mui';
import { Theme } from '@material-ui/core';
import { Page } from '../../components/app/Page';
import { BlogPostSocial } from '../../components/BlogPostSocial';
import { Typography } from '../../components/Typography';
import { TagsIcon, GlassesIcon, CalendarIcon } from '../../components/FAIcons';
import { SEO } from '../../components/app/SEO';
import { Divider } from '../../components/app/Divider';
import { Link } from '../../components/Link';
import { tagPath } from '../../lib/blogUtils';
import { FluidObject } from 'gatsby-image';

// Keeping this for when I need to make a custom component for a blog post
// const renderAst = new RehypeReact({
//     createElement: React.createElement,
//     components: {}
// }).Compiler;

type ClassKey =
    'page' | 'post' | 'header' | 'postMeta' | 'bottomNav' | 'postMetaIcon' | 'seperator' | 'hero' | 'tagDivider' |
    'image';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => {
    const postPaddingLR: number = theme.spacing(5);
    return {
        page: {
            width: '100%',
            maxWidth: '48rem',
            margin: '0 auto',
            paddingLeft: `${postPaddingLR}px`,
            paddingRight: `${postPaddingLR}px`,
        },
        post: {
            // '& .vscode-highlight-container': {
            '& .grvsc-container-wrapper': {
                boxShadow: theme.shadows[9],
                // marginLeft: `-${codeBlockMarginLRRem}`,
                // marginRight: `-${codeBlockMarginLRRem}`,
                marginLeft: `-${postPaddingLR}px`,
                marginRight: `-${postPaddingLR}px`,
            },
            // '& .vscode-highlight'
            '& .grvsc-container': {
                boxShadow: `inset 0px 5px 6px 0px ${theme.palette.custom.code.block.insetShadowColor}`
            },
        },
        header: { marginBottom: theme.baseline.blockMarginBottom, textAlign: 'center' },
        postMeta: {
            display: 'inline-flex',
            alignItems: 'center',
        },
        seperator: {
            '&:not(:last-child)': {
                marginRight: theme.spacing(3),
            }
        },
        postMetaIcon: {
            ...theme.typography.iconSizing(1),
            color: theme.palette.custom.text.color.lighter,
            marginRight: theme.spacing(2)
        },
        bottomNav: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            listStyle: 'none',
            marginLeft: 0
        },
        hero: {
            marginBottom: theme.baseline.blockMarginBottom
        },
        tagDivider: { marginRight: theme.spacing(1), marginLeft: theme.spacing(1) },
        image: {
            boxShadow: theme.shadows[6],
            borderRadius: theme.shape.borderRadius
        }
    };
});

export interface IBlogPostTemplateP {
    data: GQLBlogPostQuery;
    pageContext: GQLSitePageContext;
}

function BlogPost(props: TReactFCP<IBlogPostTemplateP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const post: GQLBlogPostQuery['markdownRemark'] = props.data.markdownRemark;
    const { previousPost, nextPost, slug }: GQLSitePageContext = props.pageContext;
    return (
        <React.Fragment>
            <SEO
                articleMeta={{
                    publishedTime: post!.frontmatter!.publishDateISO8601,
                    modifiedTime: post!.frontmatter!.modifiedDateISO8601,
                    author: {
                        name: post!.frontmatter!.author!.name!,
                        image: post!.frontmatter!.author!.image!.childImageSharp!.original!.src!,
                        website: post!.frontmatter!.author!.website!,
                        twitterHandle: post!.frontmatter!.author!.twitter!,
                    },
                    timeToRead: post!.timeToRead!,
                    tags: post!.frontmatter!.tags! as string[]
                }}
                cardSize='large'
                image={{ src: post!.frontmatter!.hero!.childImageSharp!.original!.src! }}
                slug={slug!}
                title={post!.frontmatter!.title ?? undefined}
                description={post!.frontmatter!.spoiler ?? undefined}
                type='article'
            />
            <main className={styles.page}>
                <article className={styles.post}>
                    <header className={styles.header}>
                        <Typography variant='h1' gutterTop>{post!.frontmatter!.title}</Typography>
                        <Grid container spacing={3} alignItems='center' justify='center'>
                            <Grid item className={styles.seperator}>
                                <Typography className={styles.postMeta} variant='overline' color='light'>
                                    <GlassesIcon className={styles.postMetaIcon} />
                                    {`${post!.timeToRead} min${post!.timeToRead! > 1 ? 's' : ''}`}
                                </Typography>
                            </Grid>
                            <Grid item className={styles.seperator}>
                                <Typography className={styles.postMeta} variant='overline' color='light'>
                                    <CalendarIcon className={styles.postMetaIcon} />
                                    {post!.frontmatter!.publishDate}
                                </Typography>
                            </Grid>
                            <Grid item className={styles.seperator}>
                                <Typography className={styles.postMeta} variant='overline' color='light'>
                                    <TagsIcon className={styles.postMetaIcon} />
                                    {(post!.frontmatter!.tags! as string[]).map((t: string, index: number) => (
                                        <React.Fragment key={t}>
                                            <Link
                                                // className={styles.tag}
                                                variant='internal'
                                                to={`${tagPath(t!)}`}
                                                inheritColor
                                                disableUnderline
                                            >
                                                {t!}
                                            </Link>
                                            {(index !== post!.frontmatter!.tags!.length - 1) && (
                                                <span className={styles.tagDivider}>&middot;</span>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </header>
                    <Link
                        variant='internalTab'
                        to={post!.frontmatter!.hero!.childImageSharp!.original!.src!}
                        className={styles.hero}
                    >
                        <GatsbyImage
                            className={styles.image}
                            fluid={post!.frontmatter!.hero!.childImageSharp!.fluid! as FluidObject}
                        />
                        {/* <Typography component='div' variant='caption' color='light' align='center'>Some caption</Typography> */}
                    </Link>
                    {/* <div>{renderAst(post!.htmlAst)}</div> */}
                    <div dangerouslySetInnerHTML={{ __html: post!.html! }} />
                </article>
                <BlogPostSocial
                    slug={slug!}
                    title={post!.frontmatter!.title || undefined}
                    quote={post!.frontmatter!.shareMessage || undefined}
                    description={post!.frontmatter!.description || undefined}
                    hashtags={post!.frontmatter!.hashtags as string[] || undefined}
                    twitterHandle={post!.frontmatter!.author!.twitter || undefined}
                />
                <Divider />
                <aside>
                    <ul className={styles.bottomNav}>
                        <li>
                            {previousPost && (
                                <Link
                                    variant='internal'
                                    to={previousPost.fields!.slug!}
                                    InternalLinkProps={{ rel: 'prev' }}
                                >
                                    {`<- ${previousPost.frontmatter!.title!}`}
                                </Link>
                            )}
                        </li>
                        <li>
                            {nextPost && (
                                <Link
                                    variant='internal'
                                    to={nextPost.fields!.slug!}
                                    InternalLinkProps={{ rel: 'next' }}
                                >
                                    {`${nextPost.frontmatter!.title!} ->`}
                                </Link>
                            )}
                        </li>
                    </ul>
                </aside>
            </main>
        </React.Fragment>
    );
}

export default (props: TReactFCP<IBlogPostTemplateP>): TReactFCR => <Page component={BlogPost} cprops={props} />;

export const query = graphql`
    query BlogPost($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            # htmlAst
            timeToRead
            frontmatter {
                publishDate(formatString: "MMM DD, YYYY")
                publishDateISO8601: publishDate(formatString: "")
                modifiedDateISO8601: modifiedDate(formatString: "")
                title
                spoiler
                description
                shareMessage
                hashtags
                tags
                author {
                    twitter
                    website
                    image {
                        childImageSharp{
                            original {
                                src
                            }
                        }
                    }
                    name
                }
                hero {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                        original {
                            src
                            width
                            height
                        }
                    }
                }
            }
        }
    }
`;
